import React from 'react'
import { Link } from 'gatsby';
import '@styles/404.scss';
import Layout from '../components/Layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout
        location={this.props.location}
        seo={
          <SEO title="404: Not Found" />
        }
      >
        <div className="Not-found-container">
          {/* <h1>404 Not Found</h1>
          <p>Page not available, but Laagom is.</p> */}
          <h1>404 Not Found</h1>
          <p>Page not available, but <span>Laagom</span> is.</p>
          {/* <p>We can't seem to find the page you are looking for.</p> */}
          <Link to="/">Go to home</Link>
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage
